import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { Loader } from 'components';
import PrivateRoute from './PrivateRoute';
import PdfViewer from 'modules/fund/container/PdfViewer';
import PdfViewerData from 'modules/fund/container/PdfViewerData';

const UIControls = lazy(() => import('ui-controls/UIControls'));
const UIComponents = lazy(() => import('components/UIComponents'));
const Login = lazy(() => import('../modules/login/container'));
const ResetPwd = lazy(() => import('../modules/changePwd/container'));
const Dashboard = lazy(() => import('../modules/home/container'));
const Fund = lazy(() => import('../modules/fund/container/index'));
const Error404 = lazy(() => import('./Error404'));
const PacingPlan = lazy(() => import('../modules/PacingPlan/container/index'));
const LandingPage = lazy(
  () => import('../modules/landingPage/container/index')
);
const FundManagerPage = lazy(
  () => import('../modules/fundManager/container/index')
);

const InvestmentsPage = lazy(
  () => import('../modules/investments/container/index')
);
const FundDocumentPage = lazy(
  () => import('../modules/fundDocument/container/index')
);

const routes = createRoutesFromElements(
  <Route path="/">
    <Route path="login" element={<Login />} />
    <Route path="resetPwd" element={<ResetPwd />} />
    <Route
      path=""
      element={
        <PrivateRoute>
          <LandingPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/funds"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/fund-managers"
      element={
        <PrivateRoute>
          <FundManagerPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/contacts"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/investments"
      element={
        <PrivateRoute>
          <InvestmentsPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/reporting"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/documents"
      element={
        <PrivateRoute>
          <FundDocumentPage />
        </PrivateRoute>
      }
    />
    <Route
      path="fund-review/:id"
      element={
        <PrivateRoute>
          <Fund />
        </PrivateRoute>
      }
    />
    <Route
      path="black-book"
      element={
        <PrivateRoute>
          <PdfViewer />
        </PrivateRoute>
      }
    />
    <Route
      path="black-book/:id"
      element={
        <PrivateRoute>
          <PdfViewerData />
        </PrivateRoute>
      }
    />
    <Route
      path="pacing-plan"
      element={
        <PrivateRoute>
          <PacingPlan />
        </PrivateRoute>
      }
    />
    <Route
      path="fund"
      element={
        <PrivateRoute>
          <Fund />
        </PrivateRoute>
      }
    />
    {process.env.REACT_APP_ENABLE_DEV_PAGES === 'true' && (
      <Route path="ui-controls" element={<UIControls />} />
    )}
    {process.env.REACT_APP_ENABLE_DEV_PAGES === 'true' && (
      <Route path="ui-components" element={<UIComponents />} />
    )}
    <Route path="*" element={<Error404 />} />
  </Route>
);

const router = createBrowserRouter(routes);

const Routing = () => (
  <Suspense fallback={<Loader />}>
    <RouterProvider router={router} />
  </Suspense>
);

// Named exports for Routing and router
export { Routing, router };

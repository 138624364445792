import ReactModal from 'react-modal';
import { any, bool, object, string, func } from 'prop-types';
import { IoIosClose } from 'react-icons/io';
import './styles.css';
import IconButton from 'ui-controls/button/IconButton';

const Modal = ({
  id = 'react-modal',
  isModalOpen = false,
  title = 'modal',
  onClose = () => {},
  children,
  Icon,
  titleStyle = 'delete-title',
  iconStyle = 'ic-success',
  isMailModal = false,
  RightButtonType = '',
  RightButtonOnClick = null,
  ...rest
}) => {
  return (
    <ReactModal ariaHideApp={false} id={id} isOpen={isModalOpen} {...rest}>
      <div className="flex-container">
        <div className="left-content">
          {Icon ? (
            <div className={`icon-wrapper ${iconStyle}`}>{Icon}</div>
          ) : null}
          {!isMailModal && (
            <div className={`title2 ${titleStyle}`}>{title}</div>
          )}
        </div>
        <div className="right-content">
          {RightButtonType && (
            <IconButton
              type={RightButtonType}
              onClick={RightButtonOnClick}
              isDisable={!RightButtonOnClick} // Disable if no action is provided
            />
          )}
        </div>
        <IoIosClose className="delete-close" onClick={onClose} />
      </div>
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  id: any,
  isModalOpen: bool.isRequired,
  rest: object,
  title: string,
  onClose: func,
  titleStyle: string,
  iconStyle: string,
};

export default Modal;
